import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, CircularProgress } from '@material-ui/core';
import axios from 'axios';

//https://api.precisiononcall.com:8088/api/?q=${code}
//http://localhost:8080/api/?q=${code}

const localizer = momentLocalizer(moment);

const jobTypes = [
  'Radtech', 'Circulating RN', 'PACU Nurse', 'PreOp/PACU RN', 'Surg tech', 'PCT or Other', 'SPD tech', 'Other RN', 'PreOp Nurse', 'First Assist'
];

const jobTypeColors = {
  'Radtech': '#FF6B6B',
  'Circulating RN': '#4ECDC4',
  'PACU Nurse': '#45B7D1',
  'PreOp/PACU RN': '#FFA07A',
  'Surg tech': '#98D8C8',
  'PCT or Other': '#F7DC6F',
  'SPD tech': '#BB8FCE',
  'Other RN': '#4ECDC4',
  'PreOp Nurse': '#4ECDC4',
  'First Assist': '#4C875C'
}

const minDate = moment().toDate();
const maxDate = moment().add(3, 'months').toDate();

const WebScheduler = ({ initialSiteCode }) => {
  const [errorText, setErrorText] = useState('');
  const [showError, setShowError] = useState(false);
  const [topErrorText, setTopErrorText] = useState('');
  const [showTopError, setShowTopError] = useState(false);
  const [textClass, setTextClass] = useState('errorText');
  const [events, setEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [siteCode, setSiteCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(true);
  const [newJob, setNewJob] = useState({
    title: '',
    jobType: '',
    description: '',
    start: moment().add(1, 'day').toDate(),
    end: moment().add(1, 'day').add(1, 'hour').toDate(),
  });

  const promptSiteCode = useCallback(() => {
    const code = prompt("Please enter your site code:");
    if (code) {
      setSiteCode(code);
      const newUrl = `${window.location.pathname}?q=${code}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    } else {
      promptSiteCode();
    }
  }, []);

  useEffect(() => {
    const checkSiteCode = () => {
      if (initialSiteCode) {
        setSiteCode(initialSiteCode);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const codeFromUrl = urlParams.get('q');
        if (codeFromUrl) {
          setSiteCode(codeFromUrl);
        } else {
          promptSiteCode();
        }
      }
    };

    checkSiteCode();
  }, [initialSiteCode, promptSiteCode]);

  useEffect(() => {
    if (siteCode) {
      fetchEvents(siteCode);
    }
  }, [siteCode]);

  const fetchEvents = useCallback(async (code) => {
    try {
      setLoading(true);
      const response = await axios.get(`https://api.precisiononcall.com:8088/api/?q=${code}`);
      if (response.status === 200) {
        if (response.data.jobs) {
          const fetchedEvents = response.data.jobs.map(job => ({
            title: job.title,
            jobType: job.jobType,
            start: new Date(job.startTime * 1000),
            end: new Date(job.endTime * 1000),
            shiftId: job.shiftId,
            description: job.description,
            staff: job.staff
          }));
          setEvents(fetchedEvents);
        }
        setIsAdmin(response.data.admin === true);
      } else {
        console.error('Failed to fetch events or no events returned');
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSelectSlot = (slotInfo) => {
    if (!isAdmin) return;
    const now = moment();
    const slotStart = moment(slotInfo.start);
    if (slotStart.isBefore(now.add(24, 'hours'))) {
      //alert("The WebScheduler cannot create a shift less than 24 hours from now.\nPlease call or text (941) 320-3647 for assistance");
      setTopErrorText('The WebScheduler cannot create a shift less than 24 hours from now.\nPlease call or text (941) 320-3647 for assistance');
      setShowTopError(true);
      handleErrorText();
      return;
    }
    setNewJob({
      ...newJob,
      start: moment(slotInfo.start).add(6, 'hour').toDate(),
      end: moment(slotInfo.start).add(16, 'hour').toDate(),
    });
    setShowError(false);
    setErrorText('');
    setShowTopError(false);
    setTopErrorText('');    
    setOpenModal(true);
  };

  const handleErrorText = () => {
    /*
    const newText = document.getElementById('newErrorText');
    const editText = document.getElementById('editErrorText');

    newText.classList.add('errorTextHighlight');
    editText.classList.add('errorTextHighlight');
    */
    setTextClass('errorTextHighlight');

    setTimeout(() => {
      setTextClass('errorText');
    }, "100");

    setTimeout(() => {
      setTextClass('errorTextHighlight');
    }, "600");      
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenDetailsModal(false);
    setOpenEditModal(false);
    setShowError(false);
    setErrorText('');
    setShowTopError(false);
    setTopErrorText('');    
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewJob({ ...newJob, [name]: value });
  };

  const validateJobTimes = () => {
    const start = moment(newJob.start);
    const end = moment(newJob.end);
    if (end.isBefore(start)) {
      //alert("End time cannot be before start time.");
      setShowError(true);
      setErrorText('End time cannot be before start time.');
      handleErrorText();
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateJobTimes()) return;
    if (moment(newJob.start).isBefore(moment().add(24, 'hours'))) {
      //alert("You cannot set a job to start less than 24 hours in the future.\nPlease call or text (941) 320-3647 for assistance");
      setShowError(true);
      setErrorText('You cannot set a job to start less than 24 hours in the future.\nPlease call or text (941) 320-3647 for assistance');
      handleErrorText();
      return;
    }    
    if (!jobTypes.includes(newJob.jobType)) {
      //return alert("You must select a job type");
      setShowError(true);
      setErrorText('You must select a job type');
      handleErrorText();      
      return;
    }
    if (newJob.title.length < 1) {
      setShowError(true);
      setErrorText('The Job Title cannot be blank.');
      handleErrorText(); 
      return;
    }    
    if (newJob.description.length < 1) {
      setShowError(true);
      setErrorText('The Job Description cannot be blank.');
      handleErrorText(); 
      return;
    }    
    var jobData = {};
    for (const key in newJob) {
      jobData[key] = newJob[key];
    }
    jobData.start = moment(jobData.start).unix();
    jobData.end = moment(jobData.end).unix();
    try {
      const response = await axios.post(`https://api.precisiononcall.com:8088/api/?q=${siteCode}`, jobData, {headers: {'content-type': 'application/json'}});
      if ((response.status === 200) || (response.status === 201) || (response.status === 202)) {
        const pendingJob = {
          ...newJob, title: newJob.title
        };
        setEvents([...events, pendingJob]);
        setOpenModal(false);
        setNewJob({
          title: '',
          jobType: '',
          description: '',
          start: moment().add(1, 'day').toDate(),
          end: moment().add(1, 'day').add(1, 'hour').toDate(),
        });
        setTimeout(() => {
          window.location.reload(true);
        }, "1500"); 
      } else {
        console.error('Failed to create job');
        setShowError(true);
        setErrorText('Failed to create job. Status code: ' + response.status);
        handleErrorText();
      }
    } catch (error) {
      console.error('Error creating job:', error);
      setShowError(true);
      setErrorText('Error creating job: ' + error);
      handleErrorText();      
    }
  };

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setShowError(false);
    setErrorText('');
    setShowTopError(false);
    setTopErrorText('');
    setOpenDetailsModal(true);
  };

  const handleSaveEdit = async () => {
    if (!isAdmin) return;
    if (!validateJobTimes()) return;
    if (moment(selectedEvent.start).isBefore(moment().add(24, 'hours'))) {
      //alert("You cannot edit a job that is less than 24 hours in the future.\nPlease call or text (941) 320-3647 for assistance");
      setShowError(true);
      setErrorText('You cannot edit a job that is less than 24 hours in the future.\nPlease call or text (941) 320-3647 for assistance');
      handleErrorText();      
      return;
    }
    if (moment(newJob.start).isBefore(moment().add(24, 'hours'))) {
      //alert("You cannot set a job to start less than 24 hours in the future.\nPlease call or text (941) 320-3647 for assistance");
      setShowError(true);
      setErrorText('You cannot set a job to start less than 24 hours in the future.\nPlease call or text (941) 320-3647 for assistance');
      handleErrorText(); 
      return;
    }
    if (newJob.title.length < 1) {
      setShowError(true);
      setErrorText('The Job Title cannot be blank.');
      handleErrorText(); 
      return;
    }    
    if (newJob.description.length < 1) {
      setShowError(true);
      setErrorText('The Job Description cannot be blank.');
      handleErrorText(); 
      return;
    }       
    var jobData = {};
    for (const key in selectedEvent) {
      jobData[key] = selectedEvent[key];
    }
    jobData.start = moment(newJob.start).unix();
    jobData.end = moment(newJob.end).unix(); 
    jobData.description = newJob.description;
    jobData.title = newJob.title;
    try {
      const response = await axios.put(`https://api.precisiononcall.com:8088/api/?q=${siteCode}`, jobData, {headers: {'content-type': 'application/json'}});
      if ((response.status === 200) || (response.status === 201) || (response.status === 202)) {
        const updatedEvents = events.map(e => 
          e === selectedEvent ? { ...e, title: newJob.title, start: newJob.start, end: newJob.end } : e
        );
        setEvents(updatedEvents);
        setOpenDetailsModal(false);
        setOpenEditModal(false);
        setTimeout(() => {
          window.location.reload(true);
        }, "1500");      
      } else {
        console.error('Failed to edit job');
        setShowError(true);
        setErrorText('Failed to edit job.  Status code: ' + response.status);
        handleErrorText();        
      }
    } catch (error) {
      console.error('Error editing job:', error);
      setShowError(true);
      setErrorText('Error editing job: ' + error);
      handleErrorText();      
    }
  };

  const handleEditJob = () => {
    if (moment(selectedEvent.start).isBefore(moment().add(24, 'hours'))) {
      //alert("You cannot edit a job that is less than 24 hours in the future.");
      setTopErrorText('You cannot edit a job that is less than 24 hours in the future.');
      setShowTopError(true);
      handleErrorText();      
      return;
    }
    setShowError(false);
    setErrorText('');
    setShowTopError(false);
    setTopErrorText('');
    setNewJob(selectedEvent);
    setOpenDetailsModal(false);
    setOpenEditModal(true);
  };
  
  const handleCopyJob = () => {
    setShowError(false);
    setErrorText('');
    setShowTopError(false);
    setTopErrorText('');
    setNewJob(selectedEvent);
    setOpenDetailsModal(false);
    setOpenModal(true);
    setShowTopError(false);
    setTopErrorText('');
  };  

  const handleCancelJob = async () => {
    if (!isAdmin) return;
    if (moment(selectedEvent.start).isBefore(moment().add(24, 'hours'))) {
      //alert("You cannot cancel a job that is less than 24 hours in the future.");
      setTopErrorText('You cannot cancel a job that is less than 24 hours in the future.');
      setShowTopError(true);
      handleErrorText();      
      return;
    }
    setShowTopError(false);
    setTopErrorText('');
    var jobData = {};
    for (const key in selectedEvent) {
      jobData[key] = selectedEvent[key];
    }
    jobData.start = moment(jobData.start).unix();
    jobData.end = moment(jobData.end).unix();     
    try {
      const response = await axios.delete(`https://api.precisiononcall.com:8088/api/?q=${siteCode}`, {data: jobData, headers: {'content-type': 'application/json'}});
      if ((response.status === 200) || (response.status === 201) || (response.status === 202)) {
        const updatedEvents = events.map(e => 
          e === selectedEvent ? { ...e, title: 'PENDING CANCELLATION' } : e
        );
        setEvents(updatedEvents);
        setOpenDetailsModal(false);
      } else {
        console.error('Failed to cancel job');
        setShowError(true);
        setErrorText('Failed to cancel job.  Status code: ' + response.status);
        handleErrorText();        
      }
    } catch (error) {
      console.error('Error cancelling job:', error);
      setShowError(true);
      setErrorText('Error cancelling job: ' + error);
      handleErrorText();      
    }
  };

  const eventStyleGetter = (event) => {
    let style = {
      backgroundColor: jobTypeColors[event.jobType] || '#3174ad',
    };
    if (event.title.startsWith('PENDING')) {
      style.color = 'white';
      style.borderColor = 'black';
      style.borderWidth = '2px';
      style.borderStyle = 'solid';
      if (event.title.startsWith('PENDING CANCELLATION')) {
        style.borderColor = 'red';
        style.color = 'red';
      } else if (event.title.startsWith('PENDING EDIT')) {
        style.borderColor = 'orange';
        style.color = 'orange';
      } else if (event.title.startsWith('PENDING ADDITION')) {
        style.borderColor = 'yellow';
        style.color = 'yellow';
      }
    }
    return { style };
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      {showTopError && (
        <p className={textClass}>
          {topErrorText}
        </p>
      )}      
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100vh' }}
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleEventSelect}
        views={['week', 'month', 'day', 'agenda']}
        min={minDate}
        max={maxDate}
        eventPropGetter={eventStyleGetter}
        formats={{
          eventTimeRangeFormat: ({ start, end }) =>
            `${moment(start).format('MMM DD, YYYY HH:mm')} - ${moment(end).format('MMM DD, YYYY HH:mm')}`,
        }}
        components={{
          event: (props) => (
            <div>
              <div>{props.title}</div>
              <div style={{ fontSize: '0.8em' }}>{props.event.jobType + " " + props.event.staff}</div>
            </div>
          ),
        }}
      />
      {isAdmin && (
        <Dialog open={openModal} onClose={handleCloseModal}>
          <div className="Modal-header">
            <DialogTitle>Request New Job</DialogTitle>
          </div>
          <DialogContent>
            <TextField
              name="title"
              label="Title"
              fullWidth
              value={newJob.title}
              onChange={handleInputChange}
              margin="normal"
            />
            <Select
              name="jobType"
              value={newJob.jobType}
              onChange={handleInputChange}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>Select Job Type</MenuItem>
              {jobTypes.map((type) => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </Select>
            <TextField
              name="description"
              label="Description"
              fullWidth
              multiline
              value={newJob.description}
              onChange={handleInputChange}
              margin="normal"
            />
            <TextField
              name="start"
              label="Arrival Date & Time"
              type="datetime-local"
              fullWidth
              value={moment(newJob.start).format('YYYY-MM-DDTHH:mm')}
              onChange={handleInputChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'),
                max: moment().add(3, 'months').format('YYYY-MM-DDTHH:mm'),
              }}
            />
            <TextField
              name="end"
              label="Estimated End Date & Time"
              type="datetime-local"
              fullWidth
              value={moment(newJob.end).format('YYYY-MM-DDTHH:mm')}
              onChange={handleInputChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'),
                max: moment().add(3, 'months').format('YYYY-MM-DDTHH:mm'),
              }}
            />
            {showError && (
              <p className={textClass}>
                {errorText}
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Send Request
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={openDetailsModal} onClose={handleCloseModal}>
        <div className="Modal-header">
          <DialogTitle>Job Details</DialogTitle>
        </div>
        <DialogContent>
          {selectedEvent && (
            <>
              <p><strong>Title:</strong> {selectedEvent.title}</p>
              <p><strong>Job Type:</strong> {selectedEvent.jobType}</p>
              <p><strong>Description:</strong> {selectedEvent.description}</p>
              <p><strong>Arrival:</strong> {moment(selectedEvent.start).format('MMM DD, YYYY HH:mm')}</p>
              <p><strong>Estimated End:</strong> {moment(selectedEvent.end).format('MMM DD, YYYY HH:mm')}</p>
              <p><strong>Staff Assigned:</strong> {selectedEvent.staff}</p>
            </>
          )}
          {showError && (
            <p className={textClass}>
              {errorText}
            </p>
          )}          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {isAdmin && (
            <>
              <Button onClick={handleCopyJob} color="primary">
                Copy
              </Button>            
              <Button onClick={handleEditJob} color="primary" disabled={moment(selectedEvent?.start).isBefore(moment().add(24, 'hours'))}>
                Edit
              </Button>
              <Button onClick={handleCancelJob} color="secondary" disabled={moment(selectedEvent?.start).isBefore(moment().add(24, 'hours'))}>
                Cancel Job
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={openEditModal} onClose={handleCloseModal}>
        <div className="Modal-header">
          <DialogTitle>Edit Job</DialogTitle>
        </div>
        <DialogContent>
          <TextField
            name="title"
            label="Title"
            fullWidth
            value={newJob.title}
            onChange={handleInputChange}
            margin="normal"
          />
          <p>Job Type: {newJob.jobType}</p>
          <TextField
            name="description"
            label="Description"
            fullWidth
            multiline
            value={newJob.description}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            name="start"
            label="Arrival Date & Time"
            type="datetime-local"
            fullWidth
            value={moment(newJob.start).format('YYYY-MM-DDTHH:mm')}
            onChange={handleInputChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'),
              max: moment().add(3, 'months').format('YYYY-MM-DDTHH:mm'),
            }}
          />
          <TextField
            name="end"
            label="Estimated End Date & Time"
            type="datetime-local"
            fullWidth
            value={moment(newJob.end).format('YYYY-MM-DDTHH:mm')}
            onChange={handleInputChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: moment().add(1, 'day').format('YYYY-MM-DDTHH:mm'),
              max: moment().add(3, 'months').format('YYYY-MM-DDTHH:mm'),
            }}
          />
          {showError && (
            <p className={textClass}>
              {errorText}
            </p>
          )}          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>      
    </div>
  );
};

export default WebScheduler;