import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';

const localizer = momentLocalizer(moment);

const Timeclock = ({ siteCode }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  const fetchEvents = useCallback(async (code) => {
    try {
      setLoading(true);
      const response = await axios.get(`https://api.precisiononcall.com:8088/api/?q=${code}`);
      if (response.status === 200 && response.data.punches) {
        const fetchedEvents = response.data.punches.map(punch => ({
          title: (punch.jobType + ": " + punch.staff),
          start: new Date(punch.startTime * 1000),
          end: new Date((punch.startTime * 1000) + 3600),
          allDay: true,
          staff: punch.staff,
          jobType: punch.jobType,
          description: punch.timeDescription
        }));
        setEvents(fetchedEvents);
      } else {
        console.error('Failed to fetch punches or no punches returned');
      }
    } catch (error) {
      console.error('Error fetching punches:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (siteCode) {
      fetchEvents(siteCode);
    }
  }, [siteCode, fetchEvents]);

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setOpenDetailsModal(true);
  };

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100vh' }}
        views={['week', 'month', 'day', 'agenda']}
        onSelectEvent={handleEventSelect}
        min={moment().subtract(3, 'months').toDate()}
        max={moment().endOf('month').toDate()}
      />
      <Dialog open={openDetailsModal} onClose={handleCloseModal}>
        <DialogTitle>Punch Details</DialogTitle>
        <DialogContent>
          {selectedEvent && (
            <>
              <p><strong>Assigned Staff:</strong> {selectedEvent.staff}</p>
              <p><strong>Start Time:</strong> {moment(selectedEvent.start).format('MMMM D, YYYY HH:mm')}</p>
              <p><strong>Description:</strong> {selectedEvent.description}</p>
              <p><strong>Job Type:</strong> {selectedEvent.jobType}</p>
              <p style={{ textAlign: 'center', marginTop: '20px' }}>
                Times listed are estimates and subject to adjustment
              </p>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Timeclock;