import React, { useState, useEffect } from 'react';
import WebScheduler from './WebScheduler';
import Timeclock from './Timeclock';
import axios from 'axios';
import logoImage from './POCLogo.svg'
import overlayImage from './cutoutT.svg'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import './App.css';
import useWindowSize from './useWindowSize'

//position: absolute;

function App() {
  const [greeting, setGreeting] = useState({ name: '', site: '' });
  const [errorModal, setErrorModal] = useState({ open: false, message: '' });
  const [siteCode, setSiteCode] = useState('');
  const [activeView, setActiveView] = useState('schedule');
  const [overlayClass, setOverlayClass] = useState('overlay-small');
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('q');
    if (code) {
      setSiteCode(code);
      fetchGreeting(code);
    }
    setTimeout(() => {
      setOverlayClass('overlay-large');
      setTimeout(() => {
        setShowOverlay(false);
      }, "4500");      
    }, "2500");    
  }, []);

  const fetchGreeting = async (code) => {
    try {
      const response = await axios.get(`https://api.precisiononcall.com:8088/api/?q=${code}`);
      if (response.status === 200) {
        setGreeting({ name: response.data.name, site: response.data.site });
      } else {
        setErrorModal({ open: true, message: `Error: ${response.status}` });
      }
    } catch (error) {
      setErrorModal({ open: true, message: `Error: ${error.message}` });
    }
  };

  const size = useWindowSize();

  const handleCloseErrorModal = () => {
    setErrorModal({ ...errorModal, open: false });
  };

  const toggleView = (view) => {
    setActiveView(view);
  };

  return (
    <div className="App">
      {showOverlay && <img src={overlayImage} alt="Overlay" className={overlayClass} />}
      <header className="App-header">
        <div className="header-content">          
          {size.width > 790 && <img src={logoImage} alt="Logo" className="logo" />}
          <div className="greeting">
            <h1>{greeting.name ? `Hello, ${greeting.name}` : ''}</h1>
            <h2>{greeting.site ? `${greeting.site}` : ''}</h2>
          </div>
          <div className="view-toggles">
            <Button
              variant="contained"
              color={activeView === 'schedule' ? 'primary' : 'default'}
              onClick={() => toggleView('schedule')}
              disabled={activeView === 'schedule'}
            >
              Schedule
            </Button>
            <Button
              variant="contained"
              color={activeView === 'timesheets' ? 'primary' : 'default'}
              onClick={() => toggleView('timesheets')}
              disabled={activeView === 'timesheets'}
            >
              Timesheets
            </Button>
          </div>           
        </div>
      </header>
      {activeView === 'schedule' && <WebScheduler initialSiteCode={siteCode} />}
      {activeView === 'timesheets' && <Timeclock siteCode={siteCode} />}
      <Dialog open={errorModal.open} onClose={handleCloseErrorModal}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>{errorModal.message}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;